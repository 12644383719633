<template>
  <div>
    <ModalClaimNewVue v-if="showClaimModal" @close="showClaimModal = false" />
    <header-slot>
      <template #actions v-if="validRole">
        <b-button variant="success" class="mr-1" @click="showClaimModal = true">
          <feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-50 text-white"
          />Create
        </b-button>
      </template>
    </header-slot>
    <!-- Navigation -->
    <b-nav card-header pills class="m-0">
      <!-- My List -->
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeLevelZero }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >LEVEL 0</b-nav-item
      >
      <!-- List -->
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeLevelOne }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >LEVEL 1
        <span v-if="totalPendingClaims1 > 0 && moduleId == 18" class="ml-1">
          <feather-icon
            icon
            :badge="totalPendingClaims1 > 99 ? '99+' : totalPendingClaims1"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>

      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeLevelTwo }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >LEVEL 2
        <span v-if="totalPendingClaims2 > 0 && moduleId == 18" class="ml-1">
          <feather-icon
            icon
            :badge="totalPendingClaims2 > 99 ? '99+' : totalPendingClaims2"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>

      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeLevelThree }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >LEVEL 3
        <span v-if="totalPendingClaims3 > 0 && moduleId == 16" class="ml-1">
          <feather-icon
            icon
            :badge="totalPendingClaims3 > 99 ? '99+' : totalPendingClaims3"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>

      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeAnnulled }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >ANNULLED
      </b-nav-item>
      <b-nav-item
        :to="{ name: $route.matched[1].meta.routeFinished }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >FINISHED
      </b-nav-item>
    </b-nav>

    <!-- Dinamic Route -->
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name"></router-view>
    </b-card>
  </div>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import ModalClaimNewVue from "@/views/commons/components/claims_v2/components/Modals/ModalClaimNew.vue";
export default {
  name: "LevelClaim",
  components: {
    ModalClaimNewVue,
  },
  async created() {
    await this.getStatusAccountAction();
    this.A_PENDING_CLAIMS(this.moduleId);
  },
  computed: {
    ...mapGetters({
      totalPendingClaims1: "ClaimsStore/G_PENDING_CLAIMS_1",
      totalPendingClaims2: "ClaimsStore/G_PENDING_CLAIMS_2",
      totalPendingClaims3: "ClaimsStore/G_PENDING_CLAIMS_3",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    validRole() {
      return (
        ((this.currentUser.role_id == 1 ||
          this.currentUser.role_id == 17 ||
          this.currentUser.role_id == 2) &&
          this.currentUser.modul_id != 18 &&
          this.currentUser.modul_id != 16) ??
        false
      );
    },
  },
  methods: {
    ...mapActions({
      getStatusAccountAction: "DebtSolutionZeroPayment/getStatusAccountAction",
      A_PENDING_CLAIMS: "ClaimsStore/A_PENDING_CLAIMS",
    }),
  },
  data() {
    return {
      showClaimModal: false,
    };
  },
};
</script>
  
 