export default [
  // {
  //   key: "action",
  //   label: "Select",
  //   class: "text-center",
  //   tdClass: "text-center",
  //   visible: false,
  // },
  {
    key: "client",
    label: "Client",
    visible: true,
  },
  {
    key: "program",
    label: "Program",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "advisor",
    label: "Advisor",
    visible: true,
  },
  {
    key: "chief",
    label: "SUP/CHIEF",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },

  {
    key: "email",
    label: "Email",
    visible: true,
  },

  {
    key: "action",
    label: "Action",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
]
