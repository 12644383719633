var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalSearchClient",attrs:{"size":"xlg","title":"SEARCH CLIENT FOR CLAIM","no-close-on-backdrop":true,"hide-footer":"","centered":""},on:{"hidden":_vm.closeMe}},[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-overlay',{attrs:{"show":_vm.loadingSearch,"rounded":"lg","opacity":"0.6"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"primary"}}),_c('b-spinner',{attrs:{"type":"grow","variant":"primary"}}),_c('b-spinner',{attrs:{"small":"","type":"grow","variant":"primary"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Please wait...")])],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"form"},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":"searchText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"aria-hidden":_vm.loadingSearch ? 'true' : null}},[_c('b-input-group-prepend',[_c('b-input-group-text',{staticClass:"w-100 badge-name-group"},[_c('span',[_vm._v("Client or Account Number")])])],1),_c('b-form-input',{attrs:{"type":"text","placeholder":"search by name or account number","id":"search-client","state":errors[0] ? false : null,"autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchClient()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('b-input-group-append',[_c('b-button',{staticClass:"py-0",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.searchClient()}}},[_vm._v(" SEARCH "),_c('FeatherIcon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1)]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[[_c('div',[_c('b-table',{ref:"table-search-for-claim",staticClass:"table-search-for-claim",attrs:{"select-mode":"single","sticky-header":"400px","busy":_vm.loadingSearch,"items":_vm.executeSearchClient,"fields":_vm.FieldsTable,"show-empty":"","id":"table-client-create"},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(client)",fn:function(data){return [_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"5px"}},[_c('router-link',{attrs:{"target":"_blank","to":{
                    name: _vm.dashboardRouteName,
                    params: {
                      idClient: ("" + (data.item.id)),
                    },
                  }}},[_vm._v(" "+_vm._s(data.item.client)+" ")])],1),_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(data.item.account)+" ")]),_c('status-account',{attrs:{"account":data.item}}),_c('b-col',{attrs:{"sm":"12","md":"12","lg":"6"}},[(data.item.account_json != null)?_c('b-progress',{staticClass:"w-100",attrs:{"id":((data.item.id) + "_tooltip"),"height":"1.5rem","variant":"warning","striped":""}},[_c('b-progress-bar',{attrs:{"value":"100"}},[_c('strong',[_vm._v("HAS A CLAIM "),_c('feather-icon',{attrs:{"icon":"MousePointerIcon"}})],1)])],1):_vm._e(),(data.item.account_json != null)?_c('b-tooltip',{attrs:{"target":((data.item.id) + "_tooltip"),"triggers":"hover","placement":"leftright"}},[_c('b-row',[_c('b-col',[_c('b-row',_vm._l((JSON.parse(
                            data.item.account_json
                          )),function(account,index){return _c('b-col',{key:index,staticClass:"mb-1",attrs:{"sm":"12"}},[_c('strong',[_vm._v(_vm._s(account.program)+" - ")]),_c('strong',[_vm._v(_vm._s(account.account))]),_c('b-progress',{attrs:{"max":"4","height":"1rem"}},[_c('b-progress-bar',{attrs:{"value":account.level_id,"show-progress":"","show-value":"","animated":"","variant":_vm.variantStatus(account.status),"label":'Level ' + (account.level_id - 1).toString()}})],1)],1)}),1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4","sm":"4"}},[_c('b-badge',{attrs:{"variant":"info"}},[_vm._v("Pending")])],1),_c('b-col',{attrs:{"cols":"4","sm":"4"}},[_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Finished")])],1),_c('b-col',{attrs:{"cols":"4","sm":"4"}},[_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Annulled")])],1)],1)],1):_vm._e()],1)]}},{key:"cell(action)",fn:function(data){return [_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Select client'),expression:"'Select client'",modifiers:{"hover":true,"top":true}}],staticClass:"clickable text-primary",attrs:{"icon":"NavigationIcon"},on:{"click":function($event){return _vm.onRowSelected(data.item)}}})]}}])}),_c('div',{staticClass:"d-flex justify-content-end mt-5"},[_c('b-pagination',{attrs:{"align":"fill","first-number":"","last-number":"","total-rows":_vm.dataPaginator.totalRows,"per-page":_vm.dataPaginator.paginate.perPage},on:{"change":function($event){return _vm.$refs['table-search-for-claim'].refresh()}},model:{value:(_vm.dataPaginator.paginate.currentPage),callback:function ($$v) {_vm.$set(_vm.dataPaginator.paginate, "currentPage", $$v)},expression:"dataPaginator.paginate.currentPage"}})],1)],1)]],2)],1),(_vm.showModalClient)?_c('ModalCreateClaim',{attrs:{"client":_vm.clientSelect},on:{"close":function($event){_vm.showModalClient = false},"save":function($event){return _vm.closeMe()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }