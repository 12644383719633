<template>
  <b-modal
    size="xlg"
    ref="modalSearchClient"
    title="SEARCH CLIENT FOR CLAIM"
    :no-close-on-backdrop="true"
    hide-footer
    @hidden="closeMe"
    centered
  >
    <b-row>
      <b-col cols="6" class="mt-1">
        <b-overlay :show="loadingSearch" rounded="lg" opacity="0.6">
          <template #overlay>
            <div class="d-flex align-items-center" style="gap: 5px">
              <b-spinner small type="grow" variant="primary"></b-spinner>
              <b-spinner type="grow" variant="primary"></b-spinner>
              <b-spinner small type="grow" variant="primary"></b-spinner>
              <!-- We add an SR only text for screen readers -->
              <span class="sr-only">Please wait...</span>
            </div>
          </template>
          <ValidationObserver ref="form">
            <b-form-group>
              <ValidationProvider
                v-slot="{ errors }"
                name="searchText"
                rules="required"
              >
                <b-input-group :aria-hidden="loadingSearch ? 'true' : null">
                  <b-input-group-prepend>
                    <b-input-group-text class="w-100 badge-name-group">
                      <span>Client or Account Number</span>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    placeholder="search by name or account number"
                    v-model="searchText"
                    @keyup.enter="searchClient()"
                    id="search-client"
                    :state="errors[0] ? false : null"
                    autofocus
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click="searchClient()"
                      class="py-0"
                    >
                      SEARCH
                      <FeatherIcon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </ValidationProvider>
            </b-form-group>
          </ValidationObserver>
        </b-overlay>
      </b-col>
      <b-col cols="12">
        <template>
          <div>
            <b-table
              ref="table-search-for-claim"
              class="table-search-for-claim"
              select-mode="single"
              sticky-header="400px"
              :busy="loadingSearch"
              :items="executeSearchClient"
              :fields="FieldsTable"
              show-empty
              id="table-client-create"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(client)="data">
                <span class="d-flex align-items-center" style="gap: 5px">
                  <router-link
                    target="_blank"
                    :to="{
                      name: dashboardRouteName,
                      params: {
                        idClient: `${data.item.id}`,
                      },
                    }"
                  >
                    {{ data.item.client }}
                  </router-link>
                </span>
                <span class="d-block">
                  {{ data.item.account }}
                </span>
                <status-account :account="data.item"></status-account>
                <b-col sm="12" md="12" lg="6">
                  <b-progress
                    v-if="data.item.account_json != null"
                    :id="`${data.item.id}_tooltip`"
                    height="1.5rem"
                    class="w-100"
                    variant="warning"
                    striped
                  >
                    <b-progress-bar value="100">
                      <strong
                        >HAS A CLAIM <feather-icon icon="MousePointerIcon"
                      /></strong>
                    </b-progress-bar>
                  </b-progress>
                  <b-tooltip
                    v-if="data.item.account_json != null"
                    :target="`${data.item.id}_tooltip`"
                    triggers="hover"
                    placement="leftright"
                  >
                    <b-row>
                      <b-col>
                        <b-row>
                          <b-col
                            v-for="(account, index) in JSON.parse(
                              data.item.account_json
                            )"
                            :key="index"
                            sm="12"
                            class="mb-1"
                          >
                            <strong>{{ account.program }} - </strong>
                            <strong>{{ account.account }}</strong>
                            <b-progress max="4" height="1rem">
                              <b-progress-bar
                                :value="account.level_id"
                                show-progress
                                show-value
                                animated
                                :variant="variantStatus(account.status)"
                                :label="
                                  'Level ' + (account.level_id - 1).toString()
                                "
                              />
                            </b-progress>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="4" sm="4">
                        <b-badge variant="info">Pending</b-badge>
                      </b-col>
                      <b-col cols="4" sm="4">
                        <b-badge variant="success">Finished</b-badge>
                      </b-col>
                      <b-col cols="4" sm="4">
                        <b-badge variant="danger">Annulled</b-badge>
                      </b-col>
                    </b-row>
                  </b-tooltip>
                </b-col>
              </template>
              <template #cell(action)="data">
                <feather-icon
                  icon="NavigationIcon"
                  class="clickable text-primary"
                  v-b-tooltip.hover.top="'Select client'"
                  @click="onRowSelected(data.item)"
                />
              </template>
            </b-table>

            <div class="d-flex justify-content-end mt-5">
              <b-pagination
                v-model="dataPaginator.paginate.currentPage"
                align="fill"
                first-number
                last-number
                :total-rows="dataPaginator.totalRows"
                :per-page="dataPaginator.paginate.perPage"
                @change="$refs['table-search-for-claim'].refresh()"
              />
            </div>
          </div>
        </template>
      </b-col>
    </b-row>

    <ModalCreateClaim
      v-if="showModalClient"
      @close="showModalClient = false"
      :client="clientSelect"
      @save="closeMe()"
    />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service";
import FieldTableSearchClient from "@/views/commons/components/claims_v2/data/fields.table-search-cliente.js";

import ModalCreateClaim from "@/views/commons/components/claims_v2/components/Modals/ModalCreateClaim.vue";

import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    ModalCreateClaim,
    StatusAccount,
  },
  data() {
    return {
      clientSelect: null,
      loadingSearch: false,
      searchText: "",
      dataClient: [],
      dataPaginator: {
        totalRows: 0,
        toPage: 0,
        fromPage: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
      },
      FieldTableSearchClient,
      showModalClient: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    dashboardRouteName() {
      const routers = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        22: "ce-customer-service-dashboard",
      };
      return routers[this.moduleId] || null;
    },
    FieldsTable() {
      let tableFields = FieldTableSearchClient.map((item) => {
        if (this.moduleId == 22) {
          if (item.key === "advisor") item.visible = false;
        }
        return item;
      });
      return tableFields.filter((x) => x.visible);
    },
    typeClient() {
      switch (this.moduleId) {
        case 6:
          return 1;
        case 22:
          return 2;
        default:
          return null;
      }
    },
  },
  mounted() {
    this.toggleModal("modalSearchClient");
    setTimeout(() => {
      this.$refs.form.reset();
    }, 100);
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
    async executeSearchClient() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      this.loadingSearch = true;
      try {
        const { perPage, currentPage } = this.dataPaginator.paginate;
        const { data, total, to, from, current_page } =
          await ClaimV2Service.searchClientForClaim({
            search: this.searchText,
            moduleId: this.moduleId,
            currentPage: currentPage,
            perPage,
            typeClient: this.typeClient,
          });
        this.dataClient = data;
        this.dataPaginator.totalRows = total;
        this.dataPaginator.toPage = to;
        this.dataPaginator.fromPage = from;
        this.dataPaginator.paginate.currentPage = current_page;
        return this.dataClient;
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.loadingSearch = false;
      }
    },

    async searchClient() {
      this.$refs["table-search-for-claim"].refresh();
    },

    onRowSelected(item) {
      this.clientSelect = item;
      this.showModalClient = true;
    },
    variantStatus(status) {
      const statusMap = {
        PENDING: "info",
        COMPLETED: "success",
        ANNULLED: "danger",
        SCALED: "info",
      };

      return statusMap[status] || "";
    },
  },
};
</script>
<style scoped>
@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* aplicar la animación al icono */
.icono {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
